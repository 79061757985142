
export default function GradeCard({ grade, label }) {
  
  const gradeClassMap = {
    'A': 'bg-success text-white',
    'B': 'bg-success text-white',
    'C': 'bg-warning text-dark',
    'D': 'bg-warning text-white',
    'F': 'bg-danger text-white'
  };

  const bgClass = gradeClassMap[grade] || 'alert-secondary';

  return (
    <div 
      className={`card ${bgClass} text-center p-3,`} 
      role="region" 
      style={{paddingTop: '2rem', paddingBottom: '2rem'}} 
      aria-labelledby={`grade-card-${label.replace(/\s+/g, '-')}`}
      tabIndex="0"
    >
      <div 
        className="card-title mb-2 h4" 
        id={`grade-card-${label.replace(/\s+/g, '-')}`}
      >
        {label}
      </div>
      <p className="card-text mb-0 h2" aria-live="polite">{grade}</p>
    </div>
  );
}
