import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import BodySection from '../components/BodySection';

export default function MainPage() {
  return (
    <div>
      <NavBar />
      <main role="main" className="container mt-5" tabIndex="0">
        <header className="alert alert-secondary mb-0 py-3" style={{ backgroundColor: "#EFF4FF" }}>
          <h1 className="text-black text-center mb-0 fw-bold" id="main-title">
            The State of K-12 Websites, 2024
          </h1>
        </header>
      </main>
      <section role="document" aria-labelledby="main-title" tabIndex="0">
        <BodySection />
      </section>
      <Footer />
    </div>
  );
}
