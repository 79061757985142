import { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const newData = [
  { "bin": "2-802", "height": 640 },
  { "bin": "802-1202", "height": 74 },
  { "bin": "1202-1602", "height": 58 },
  { "bin": "1602-2402", "height": 49 },
  { "bin": "2402-4002", "height": 9 },
  { "bin": "4002-9602", "height": 0 },
  { "bin": "9602-14402", "height": 1 },
  { "bin": "14402-24002", "height": 1 },
];

export default function BackendDurationPerformance() {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleBarClick = (data) => {
    if (selectedItem === null) {
      setSelectedItem(data);
      return;
    }

    if (selectedItem.payload !== data.payload) {
      setSelectedItem(data);
    } else {
      setSelectedItem(null);
    }
  };

  return (
    <div className="card" style={{ borderRadius: "10px" }}>
      <div className="card-header">
        <p className="h3 modal-title fw-bold">
          Backend Duration Performance Metrics
        </p>
      </div>

      <div className="card-body">
        <div className="container">

          <div className="row">
            <div className="col-12">
              <p tabIndex="0">
                Backend duration refers to the time taken by the server to process requests and respond to users. Extended backend durations can lead to delays in loading content, impacting user satisfaction and engagement. Efficient backend performance is essential for providing a seamless experience, especially during peak usage times.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
              <div style={{ width: "100%", height: "500px" }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={newData}
                    layout="vertical"
                    margin={{ top: 20, right: 30, bottom: 5, left: 60 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis type="category" dataKey="bin" />
                    <XAxis type="number" />
                    <Bar
                      dataKey="height"
                      fill="#8884d8"
                      onClick={handleBarClick}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>

            <div className="col-12 col-lg-6 d-flex align-items-center">
              <div className="row justify-content-center" style={{ width: "100%" }}>
                <div className="col-md-12 col-lg-10 mb-4">
                  <div className="table-responsive" style={{ margin: "1rem" }}>
                    <table className="table table-bordered table-striped">
                      <caption className="sr-only">
                        Table showing the backend duration performance metrics for K12 websites
                      </caption>
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">Response Time (in ms)</th>
                          <th scope="col">Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {newData.map((item, index) => (
                          <tr key={index}>
                            <td tabIndex="0">{item.bin}</td>
                            <td tabIndex="0">{item.height}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {selectedItem ? (
        <div
          className="card mt-4"
          style={{
            borderRadius: "10px",
            marginRight: "10px",
            marginLeft: "10px",
            marginBottom: "5px",
          }}
        >
          <div className="card-header">
            <p className="h4 modal-title" tabIndex="0">
              Backend Duration Performance Details
            </p>
          </div>
          <div className="card-body">
            <p tabIndex="0">
              <strong>Response Time ({selectedItem.payload.bin}) (in milliseconds): </strong>
              {parseInt(selectedItem.payload.height, 10)} websites
            </p>
          </div>
        </div>
      ) : (
        <p className="text-center" tabIndex="0">
          Click a bar to see detailed performance metrics.
        </p>
      )}
    </div>
  );
}
