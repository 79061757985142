let counter = 0;

export default function StatCard({ icon, number, label, cardStyle={}, extraCardClasses="" }) {
  const uniqueId = counter++;
  const safeLabel = label.replace(/\s+/g, '-');

  return (
    <div 
      className={`card ${extraCardClasses} text-center`} 
      role="region"
      style={cardStyle}
      aria-labelledby={`stat-card-${uniqueId}-${safeLabel}`}
    >
      <div className="card-body">
        <i 
          className={`bi ${icon} fs-3 mb-2`} 
          aria-hidden="true"
        ></i>
        <h2 
          className="card-title mb-2" 
          id={`stat-card-${uniqueId}-${safeLabel}`}
          tabIndex="0"
        >
          {number}
        </h2>
        <p 
          className="card-text mb-0" 
          tabIndex="0"
        >
          <strong>{label}</strong>
        </p>
      </div>
    </div>
  );
}
