import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import AccessibilityStatement from '../components/AccessibilityStatement';

export default function AccessibilityStatementPage() {
  
  return (
    <div>
      <NavBar />
      <main role="main" className="container mt-5" tabIndex="0">
        <section aria-labelledby="accessibility-statement-section" tabIndex="0">
          <AccessibilityStatement />
        </section>
      </main>
      <Footer />
    </div>
  );
}
