import PageLoadSpeedometer from "./PageLoadSpeedometer";

export default function AveragePageLoadSpeed() {
  return (
    <div className="card" style={{ borderRadius: "10px" }}>
      <div className="card-header">
        <p className="h3 modal-title fw-bold">Average Page Load Speed</p>
      </div>
      <div className="card-body">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 d-flex align-items-center">
              <p tabIndex="0">
                The average page load speed for the audited school websites is 12 seconds, significantly higher than the ideal load speed of under 3 seconds. Fast page load speeds are crucial as they enhance user experience, reduce bounce rates, and improve accessibility. Slow loading times can frustrate users, particularly those on mobile devices or with slower internet connections, leading to higher abandonment rates and lower engagement.
              </p>
            </div>

            <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
              <div className="col-md-6 col-lg-4 mb-4">
                <PageLoadSpeedometer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
