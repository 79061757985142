import { useState, useEffect, useMemo } from "react";
import { geoCentroid } from "d3-geo";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Annotation,
} from "react-simple-maps";

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const allStates = [
  { id: "AL", val: "01" },
  { id: "AK", val: "02" },
  { id: "AS", val: "60" },
  { id: "AZ", val: "04" },
  { id: "AR", val: "05" },
  { id: "CA", val: "06" },
  { id: "CO", val: "08" },
  { id: "CT", val: "09" },
  { id: "DE", val: "10" },
  { id: "DC", val: "11" },
  { id: "FL", val: "12" },
  { id: "FM", val: "64" },
  { id: "GA", val: "13" },
  { id: "GU", val: "66" },
  { id: "HI", val: "15" },
  { id: "ID", val: "16" },
  { id: "IL", val: "17" },
  { id: "IN", val: "18" },
  { id: "IA", val: "19" },
  { id: "KS", val: "20" },
  { id: "KY", val: "21" },
  { id: "LA", val: "22" },
  { id: "ME", val: "23" },
  { id: "MH", val: "68" },
  { id: "MD", val: "24" },
  { id: "MA", val: "25" },
  { id: "MI", val: "26" },
  { id: "MN", val: "27" },
  { id: "MS", val: "28" },
  { id: "MO", val: "29" },
  { id: "MT", val: "30" },
  { id: "NE", val: "31" },
  { id: "NV", val: "32" },
  { id: "NH", val: "33" },
  { id: "NJ", val: "34" },
  { id: "NM", val: "35" },
  { id: "NY", val: "36" },
  { id: "NC", val: "37" },
  { id: "ND", val: "38" },
  { id: "MP", val: "69" },
  { id: "OH", val: "39" },
  { id: "OK", val: "40" },
  { id: "OR", val: "41" },
  { id: "PW", val: "70" },
  { id: "PA", val: "42" },
  { id: "PR", val: "72" },
  { id: "RI", val: "44" },
  { id: "SC", val: "45" },
  { id: "SD", val: "46" },
  { id: "TN", val: "47" },
  { id: "TX", val: "48" },
  { id: "UM", val: "74" },
  { id: "UT", val: "49" },
  { id: "VT", val: "50" },
  { id: "VA", val: "51" },
  { id: "VI", val: "78" },
  { id: "WA", val: "53" },
  { id: "WV", val: "54" },
  { id: "WI", val: "55" },
  { id: "WY", val: "56" },
];

const violationsData = {
  Arkansas: 169546,
  Wisconsin: 127398,
  Kentucky: 126040,
  Michigan: 126006,
  Pennsylvania: 117272,
  Ohio: 111651,
  Tennessee: 105616,
  "Rhode Island": 93616,
  Washington: 93060,
  Alabama: 91440,
  Oregon: 88959,
  Vermont: 86423,
  Colorado: 86308,
  Virginia: 83440,
  Louisiana: 83324,
  Utah: 81145,
  Minnesota: 80989,
  Iowa: 80899,
  Illinois: 80380,
  Alaska: 78207,
  Georgia: 78174,
  Florida: 76162,
  "South Dakota": 75714,
  Idaho: 74195,
  Texas: 73183,
  "New Jersey": 72827,
  Nebraska: 70743,
  "South Carolina": 70036,
  Kansas: 66827,
  Indiana: 63188,
  Maryland: 63153,
  Delaware: 58176,
  Massachusetts: 57420,
  Connecticut: 55743,
  Missouri: 54399,
  California: 52199,
  Maine: 50556,
  "West Virginia": 47911,
  Oklahoma: 43042,
  Wyoming: 36427,
  Nevada: 33442,
  Montana: 33208,
  Arizona: 30130,
  Mississippi: 27018,
  "North Dakota": 26836,
  "New Hampshire": 26724,
  Hawaii: 25217,
  "North Carolina": 23946,
  "New York": 9280,
  "New Mexico": 9112,
};

const sortViolationsAndAssignRank = (data) => {
  return Object.entries(data)
    .sort(([, a], [, b]) => b - a) // Sort by values (number of violations) in descending order
    .map(([state, numberOfViolations], index) => ({
      state,
      numberOfViolations,
      rank: Object.entries(data).length - index, // Assign rank based on the index
    }))
    .reverse(); // Return accended sorted array of objects
};

const statesTableData = sortViolationsAndAssignRank(violationsData);

const offsets = {
  VT: [50, -8],
  NH: [34, 2],
  MA: [30, -1],
  RI: [28, 2],
  CT: [35, 10],
  NJ: [34, 1],
  DE: [33, 0],
  MD: [47, 10],
  DC: [49, 21],
};

const colorPalette = ["#2CCB6F", "#F0C10F", "#E77E22", "#3399DB", "#E94C3D"];
const mapColor = "#5FB351";
const lowIssueColor = colorPalette[0];
const normalIssuesColor = colorPalette[1];
const mediumIssueColor = colorPalette[2];
const highIssueColor = colorPalette[3];
const criticalIssueColor = colorPalette[4];

export default function UsStatesMapAccessibility() {
  const [hoveredState, setHoveredState] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [stateColors, setStateColors] = useState({});
  const [colorCounts, setColorCounts] = useState({});
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const totalViolations = Object.values(violationsData).reduce(
    (acc, val) => acc + val,
    0
  );
  const averageViolations =
    totalViolations / Object.keys(violationsData).length;

  const thresholds = useMemo(
    () => [
      averageViolations / 2,
      averageViolations,
      averageViolations * 1.5,
      averageViolations * 2,
    ],
    [averageViolations]
  );

  useEffect(() => {
    setSelectedState(statesTableData[statesTableData.length - 1].state);

    const counts = colorPalette.reduce((acc, color) => {
      acc[color] = 0;
      return acc;
    }, {});

    const colors = {};

    Object.keys(violationsData).forEach((state) => {
      let color;
      if (violationsData[state] <= thresholds[0]) {
        color = lowIssueColor;
      } else if (violationsData[state] <= thresholds[1]) {
        color = normalIssuesColor;
      } else if (violationsData[state] <= thresholds[2]) {
        color = mediumIssueColor;
      } else if (violationsData[state] <= thresholds[3]) {
        color = highIssueColor;
      } else {
        color = criticalIssueColor;
      }

      colors[state] = color;
      counts[color]++;
    });

    setStateColors(colors);

    const totalStates = Object.keys(violationsData).length;

    const percentages = Object.fromEntries(
      Object.entries(counts).map(([color, count]) => [
        color,
        (count / totalStates) * 100,
      ])
    );

    setColorCounts(percentages);
  }, [thresholds]);

  const handleStateClick = (state) => {
    setSelectedState(state);
  };

  const handleStateHover = (state, event) => {
    setHoveredState(null);
    setTooltipPosition(null);

    return;

    // eslint-disable-next-line
    setHoveredState(state);

    const x = event.clientX + 10;
    const y = event.clientY + 10 + window.scrollY;

    const adjustedX = Math.min(window.innerWidth - 220, Math.max(0, x - 200));
    const adjustedY = Math.min(window.innerHeight - 60, Math.max(0, y));

    setTooltipPosition({ x: adjustedX, y: adjustedY });
  };

  const renderPercentageBar = () => {
    return (
      <div
        className="d-flex justify-content-center mb-4"
        style={{ marginTop: "1rem" }}
      >
        <div className="d-flex justify-content-between me-2">
          <span
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginRight: "0.5rem",
            }}
          >
            Lowest
          </span>
        </div>
        {colorPalette.map((color, index) => (
          <div
            key={index}
            className="position-relative me-2"
            style={{ width: "50px", height: "25px", backgroundColor: color }}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            mightbetitle={`${colorCounts[color]?.toFixed(2)}%`}
          />
        ))}
        <div className="d-flex justify-content-between ms-2">
          <span style={{ fontSize: "16px", fontWeight: "bold" }}>Highest</span>
        </div>
      </div>
    );
  };

  const tooltipContent = hoveredState ? (
    <div
      style={{
        position: "absolute",
        left: tooltipPosition.x,
        top: tooltipPosition.y,
        backgroundColor: "#fff",
        padding: "10px",
        borderRadius: "8px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <span>
        State: {hoveredState} <br />
        {/* Mean number of violations: {violationsData[hoveredState] || 0} <br /> */}
        Number of Violations: {violationsData[hoveredState] || 0}
      </span>
    </div>
  ) : null;

  return (
    <div className="card" style={{ borderRadius: "10px" }}>
      <div className="card-header">
        <p className="h3 modal-title fw-bold" tabIndex="0">
          Interactive Map of Web Accessibility by State
        </p>
      </div>
      <div className="card-body">

        <div className="container">
          <div className="row">
            <div className="col-12">
              <span className="h5 fw-bold" tabIndex="0">
                Geographical Distribution of Accessibility Violations
              </span>
              <p tabIndex="0">
                Explore our interactive map to understand how K-12 schools in each state are performing in terms of web accessibility. Click on a state to access the total number of accessibility violations and the average number of violations per website.
              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="d-block" style={{ width: "100%" }}>
                <div
                  style={{
                    height: "auto",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ComposableMap
                    projection="geoAlbersUsa"
                    style={{ height: "450px" }}
                  >
                    <Geographies geography={geoUrl}>
                      {({ geographies }) => (
                        <>
                          {geographies.map((geo) => (
                            <Geography
                              key={geo.rsmKey}
                              stroke="#000"
                              geography={geo}
                              fill={
                                violationsData[geo.properties.name]
                                  ? stateColors[geo.properties.name]
                                  : mapColor
                              }
                              onClick={() =>
                                handleStateClick(geo.properties.name)
                              }
                              onMouseEnter={(event) => {
                                handleStateHover(geo.properties.name, event);
                              }}
                            />
                          ))}
                          {geographies.map((geo) => {
                            const centroid = geoCentroid(geo);
                            const cur = allStates.find(
                              (s) => s.val === geo.properties.name
                            );

                            return (
                              <g key={geo.rsmKey + "-name"}>
                                {cur &&
                                  centroid[0] > -160 &&
                                  centroid[0] < -67 &&
                                  (Object.keys(offsets).indexOf(cur.id) ===
                                  -1 ? (
                                    <Marker coordinates={centroid}>
                                      <text
                                        y="2"
                                        fontSize={10}
                                        textAnchor="middle"
                                        fill="#FFF"
                                      >
                                        {cur.id}
                                      </text>
                                    </Marker>
                                  ) : (
                                    <Annotation
                                      subject={centroid}
                                      dx={offsets[cur.id][0]}
                                      dy={offsets[cur.id][1]}
                                    >
                                      <text
                                        x={4}
                                        fontSize={10}
                                        alignmentBaseline="middle"
                                      >
                                        {cur.id}
                                      </text>
                                    </Annotation>
                                  ))}
                              </g>
                            );
                          })}
                        </>
                      )}
                    </Geographies>
                  </ComposableMap>
                  {tooltipContent}
                </div>
                <div className="clearfix"></div>
                {renderPercentageBar()}
              </div>
            </div>
            <div className="col-12 col-lg-6 d-flex align-items-center">
              <div className="d-block" style={{ width: "100%" }}>
                {selectedState ? (
                  <div className="card mt-4" style={{ borderRadius: "10px" }}>
                    <div className="card-header">
                      <div className="h4 modal-title" tabIndex="0">
                        <div
                          style={{
                            width: "17px",
                            height: "17px",
                            backgroundColor: stateColors[selectedState],
                            display: "inline-block",
                          }}
                        ></div>&nbsp;
                        {selectedState} Accessibility Stats
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="mb-3">
                        <p
                          className="h5 card-subtitle mb-2 text-black"
                          tabIndex="0"
                        >
                          Number of Violations
                        </p>
                        <p className="card-text fs-5">
                          <span id="violations-number" tabIndex="0">
                            {parseInt(
                              statesTableData.find(
                                (item) => item.state === selectedState
                              ).numberOfViolations || 0,
                              10
                            ).toLocaleString()}
                          </span>
                        </p>
                      </div>
                      <div className="clearfix">
                        <hr />
                      </div>
                      <div className="mb-3">
                        <p
                          className="h5 card-subtitle mb-2 text-black"
                          tabIndex="0"
                        >
                          Average Number of Issues
                        </p>
                        <p className="card-text fs-5">
                          <span id="average-issues" tabIndex="0">
                            {(
                              parseInt(
                                statesTableData.find(
                                  (item) => item.state === selectedState
                                ).numberOfViolations || 0,
                                10
                              ) / 20
                            ).toLocaleString()}
                          </span>
                        </p>
                      </div>
                      <div className="clearfix">
                        <hr />
                      </div>
                      <div className="mb-3">
                        <p
                          className="h5 card-subtitle mb-2 text-black"
                          tabIndex="0"
                        >
                          Rank
                        </p>
                        <p className="card-text fs-5">
                          <span id="average-issues" tabIndex="0">
                            {parseInt(
                              statesTableData.find(
                                (item) => item.state === selectedState
                              ).rank || 0,
                              10
                            ).toLocaleString()}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <p className="text-center" tabIndex="0">
                      Click a state to see the details.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="clearfix"></div>

      <div className="container">
        <div className="row">
          <div className="col-12">
            <span className="h5 fw-bold" tabIndex="0">
              Web Accessibility Violations: State-by-State Rankings
            </span>
            <p tabIndex="0">
              The table below ranks states from best to worst in terms of web
              accessibility violations on K-12 school websites. This ranking
              provides a clear overview of which states have the most and least
              violations, offering valuable insights into the current state of
              web accessibility in education.
            </p>
          </div>
        </div>
      </div>

      <div className="clearfix"></div>

      <div className="row justify-content-center">
        <div className="col-md-12 col-lg-10 mb-4">
          <div className="table-responsive" style={{ margin: "1rem" }}>
            <table className="table table-bordered table-striped">
              <caption className="text-center text-black">
                Ranking of Issue Counts by State
              </caption>
              <thead>
                <tr>
                  <th scope="col">Rank</th>
                  <th scope="col">State</th>
                  <th scope="col">Number of Issues</th>
                </tr>
              </thead>
              <tbody>
                {!showAll ? (
                  <>
                    {statesTableData.slice(0, 3).map((item, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          if (typeof item.state === "string") {
                            setSelectedState(item.state);
                          }
                        }}
                      >
                        <td>{item.rank}</td>
                        <td>
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              backgroundColor: stateColors[item.state],
                              display: "inline-block",
                            }}
                          ></div>&nbsp;
                          {item.state}
                        </td>
                        <td>{item.numberOfViolations.toLocaleString()}</td>
                      </tr>
                    ))}

                    <tr>
                      <td>...</td>
                      <td>...</td>
                      <td>...</td>
                    </tr>

                    {statesTableData.slice(-3).map((item, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          if (typeof item.state === "string") {
                            setSelectedState(item.state);
                          }
                        }}
                      >
                        <td>{item.rank}</td>
                        <td>
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              backgroundColor: stateColors[item.state],
                              display: "inline-block",
                            }}
                          ></div>&nbsp;
                          {item.state}
                        </td>
                        <td>{item.numberOfViolations.toLocaleString()}</td>
                      </tr>
                    ))}
                  </>
                ) : (
                  statesTableData.map((item, index) => (
                    <tr
                      key={index}
                      onClick={() => {
                        if (typeof item.state === "string") {
                          setSelectedState(item.state);
                        }
                      }}
                    >
                      <td>{item.rank}</td>
                      <td>
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            backgroundColor: stateColors[item.state],
                            display: "inline-block",
                          }}
                        ></div>&nbsp;
                        {item.state}
                      </td>
                      <td>{item.numberOfViolations.toLocaleString()}</td>
                    </tr>
                  ))
                )}
                <tr>
                  <td colSpan="3" className="text-center">
                    <span
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        textDecoration: "underline",
                        userSelect: "none",
                      }}
                      onClick={toggleShowAll}
                    >
                      {showAll ? "See Less" : "See More"}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="clearfix"></div>
    </div>
  );
}
