import { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const newData = [
  { bin: "0-5000", height: 988 },
  { bin: "5000-10000", height: 140 },
  { bin: "10000-15000", height: 39 },
  { bin: "15000-20000", height: 20 },
  { bin: "20000-25000", height: 10 },
  { bin: "25000-30000", height: 7 },
  { bin: "30000-35000", height: 5 },
  { bin: "35000-40000", height: 1 },
  { bin: "40000-45000", height: 0 },
  { bin: "45000-50000", height: 0 },
  { bin: "50000-55000", height: 1 },
  { bin: "55000-60000", height: 1 },
];

export default function WebsitesWithAccessibilityIssues() {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleBarClick = (data) => {
    if (selectedItem === null) {
      setSelectedItem(data);
      return;
    }

    if (selectedItem.payload !== data.payload) {
      setSelectedItem(data);
    } else {
      setSelectedItem(null);
    }
  };

  return (
    <div className="card" style={{ borderRadius: "10px" }}>
      <div className="card-header">
        <p className="h3 modal-title fw-bold">
          Websites with Accessibility Issues
        </p>
      </div>

      <div className="card-body">
        <div className="container">
          <div className="row">
            <p tabIndex="0">
              This chart shows the number of websites with accessibility issues
              grouped by the count of issues. The x-axis represents the number
              of websites and the y-axis represents the count of issues. Click a
              bar to see the details.
            </p>
          </div>
          <div className="row">
            <div
              className="col-12 col-lg-6 d-flex align-items-center justify-content-center"
            >
              <div
                style={{
                  width: "100%",
                  height: "500px",
                }}
              >
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={newData}
                    layout="vertical"
                    margin={{ top: 20, right: 30, bottom: 5, left: 60 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis type="category" dataKey="bin" />
                    <XAxis type="number" />
                    <Bar
                      dataKey="height"
                      fill="#8884d8"
                      onClick={handleBarClick}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>

            <div className="col-12 col-lg-6 d-flex align-items-center">
              <div className="row justify-content-center" style={{ width: "100%" }}>
                <div className="col-md-12 col-lg-10 mb-4">
                  <div className="table-responsive" style={{ margin: "1rem" }}>
                    <table className="table table-bordered table-striped">
                      <caption className="sr-only">
                        Table showing the count of accessibility issues per website
                      </caption>
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">Issues per Website</th>
                          <th scope="col">Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {newData.map((item, index) => (
                          <tr key={index}>
                            <td tabIndex="0">{item.bin}</td>
                            <td tabIndex="0">{item.height}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>

      {selectedItem ? (
        <div
          className="card mt-4"
          style={{
            borderRadius: "10px",
            marginRight: "10px",
            marginLeft: "10px",
            marginBottom: "5px",
          }}
        >
          <div className="card-header">
            <p className="h4 modal-title" tabIndex="0">
              Count of Accessibility Issues per website
            </p>
          </div>
          <div className="card-body">
            <p tabIndex="0">
              <strong>Issue Count ({selectedItem.payload.bin}): </strong>
              {parseInt(selectedItem.payload.height, 10)}
            </p>
          </div>
        </div>
      ) : (
        <p className="text-center" tabIndex="0">
          Click a bar to see the details.
        </p>
      )}
    </div>
  );
}
