import { useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

const newData = [
  { name: '4.1.1', nameDescriptor: "Parsing", value: 286651 },
  { name: '1.3.1', nameDescriptor: "Info and Relationships", value: 120480 },
  { name: '4.1.2', nameDescriptor: "Name, Role, Value", value: 114701 },
  // { name: '2.4.1', nameDescriptor: "", value: 37537 },
  // { name: '3.2.2', nameDescriptor: "", value: 24724 },
];

// Descriptions for accessibility criteria
const descriptions = {
  '4.1.1': 'Web content must be correctly parsed by user agents, including assistive technologies. This requires complete start and end tags, proper nesting, and unique IDs to ensure accurate interpretation.',
  '1.3.1': 'Information, structure, and relationships conveyed through presentation should be programmatically determined or available in text.',
  '4.1.2': 'User interface components need to have programmatically determinable names, roles, and values, with changes communicated to user agents, including assistive technologies.',
  // '2.4.1': 'Ensure that users can navigate the website effectively. This includes providing clear and consistent navigation options, ensuring that users can find and access different sections of the site easily, and providing multiple ways to navigate.',
  // '3.2.2': 'Ensure that users receive clear and consistent feedback about their interactions. This includes providing error messages and instructions for correcting mistakes, and ensuring that changes in content or functionality are communicated clearly to users.',
};

export default function TopFiveAccessibilityIssues() {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleBarClick = (data) => {
    if (selectedItem === null) {
      setSelectedItem(data);
      return;
    }

    if (selectedItem.payload !== data.payload) {
      setSelectedItem(data);
    } else {
      setSelectedItem(null);
    }
  };

  return (
    <div className="card" style={{ borderRadius: "10px" }}>
      <style>
        {`
          .selected-row {
            font-weight: bold;
            text-shadow: 0px solid #000;
          }
        `}
      </style>
      <div className="card-header">
        <p className="h3 modal-title fw-bold">Most Frequent Accessibility Issues</p>
      </div>
      <div className="card-body">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 d-flex align-items-center">
              <p tabIndex="0">
                These accessibility issues are related to assistive technologies. Some programs and plug-ins rely on an expected structure and organization of the website to be able to corretly transmit the information to the user visiting a website. Such as readers for deaf users and freshable screens for users who have vision disabilities.
              </p>
            </div>

            <div className="col-12 col-lg-6" style={{ height: "400px", paddingLeft: "1rem", paddingRight: "1rem" }}>
              <ResponsiveContainer>
                <BarChart
                  data={newData}
                  layout="vertical"
                  margin={{ top: 20, right: 30, bottom: 5, left: 30 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <YAxis type="category" dataKey="name" />
                  <XAxis type="number" />
                  <Bar dataKey="value" fill="#8884d8" onClick={handleBarClick} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>

      <div>
        <p className="text-center" tabIndex="0">Click a bar to highlight the details in the table below.</p>
      </div>

      {/* {selectedItem ? (
        <div className="card mt-4" style={{ borderRadius: "10px", marginRight: "10px", marginLeft: "10px", marginBottom: "5px" }}>
          <div className="card-header">
            <p className="h4 modal-title" tabIndex="0">WCAG Criteria {selectedItem.name} Details</p>
          </div>
          <div className="card-body">
            <p tabIndex="0"><strong>Total WCAG {selectedItem.name} Issues:</strong> {selectedItem.value.toLocaleString()}</p>
            <p tabIndex="0"><strong>WCAG Criteria Description:</strong> {descriptions[selectedItem.name]}</p>
          </div>
        </div>
      ) : (
        <div>
          <p className="text-center" tabIndex="0">Click a bar to see the details.</p>
        </div>
      )} */}

      <div className="clearfix"></div>

      <div className="row justify-content-center">
        <div className="col-md-12 col-lg-10 mb-4">
          <div className="table-responsive" style={{ margin: "1rem" }}>
            <table className="table table-bordered table-striped">
                <caption className="text-center text-black">Most Frequent Accessibility Issues</caption>
                <thead>
                  <tr>
                    <th scope="col">WCAG Success Criterion</th>
                    <th scope="col">Description</th>
                    <th scope="col">Count</th>
                  </tr>
                </thead>
                <tbody>
                  {newData.map((item, index) => (
                    <tr
                      key={index}
                      className={selectedItem && selectedItem.payload.name === item.name ? 'selected-row' : ''}
                    >
                      <td>{item.name} {item.nameDescriptor}</td>
                      <td>{descriptions[item.name]}</td>
                      <td>{item.value.toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
  );
};
