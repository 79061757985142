import StatCard from './StatCard';

export default function GeneralStatsOverview() {
  return (
    <div className="row justify-content-center">
      <div className="col-md-6 col-lg-4 mb-4">
        <StatCard icon="bi-building" number="1,200" label="Schools Included" cardStyle={{ backgroundColor: "#f7fff3" }} />
      </div>
      <div className="col-md-6 col-lg-4 mb-4">
        <StatCard icon="bi-geo-alt" number="685" label="Counties Covered" cardStyle={{ backgroundColor: "#f7fff3" }} />
      </div>
      <div className="col-md-6 col-lg-4 mb-4">
        <StatCard icon="bi-map" number="50" label="States Represented" cardStyle={{ backgroundColor: "#f7fff3" }} />
      </div>
      <div className="col-md-6 col-lg-4 mb-4">
        <StatCard icon="bi-geo" number="398" label="Districts Spanned" cardStyle={{ backgroundColor: "#f7fff3" }} />
      </div>
      <div className="col-md-6 col-lg-4 mb-4">
        <StatCard icon="bi-menu-button-wide-fill" number="87" label="CMS Platforms Analyzed" cardStyle={{ backgroundColor: "#f7fff3" }} />
      </div>
    </div>
  );
}
