
export default function Footer() {
  let currentYear = new Date().getFullYear();

  if (currentYear < 2024 || currentYear > 2090) {
    currentYear = 2024;
  }

  return (
    <footer className="bg-light text-center text-lg-start py-4" style={{ marginTop: '1rem' }}>
      <div className="container" tabIndex="0">
        <hr />
        <div className="row">
          <div className="col-lg-4 mb-4 mb-lg-0">
            <p className="h5 mb-4" tabIndex="0" aria-labelledby="Quick links section of the footer. These links take you to their respective pages.">Quick Links</p>
            <ul className="list-unstyled">
              <li className="mb-3">
                <a 
                  href="https://allyright.com/contact-us" 
                  target="_blank" 
                  rel="noreferrer" 
                  aria-label="Contact us"
                  className="text-dark link-offset-3"
                >
                  Contact Us
                </a>
              </li>
              <li className="mb-3">
                <a 
                  href="https://allyright.com/privacy-policy" 
                  target="_blank" 
                  rel="noreferrer" 
                  aria-label="Privacy Policy"
                  className="text-dark link-offset-3"
                >
                  Privacy Policy
                </a>
              </li>
              <li className="mb-3">
                <a 
                  href="/accessibility-statement" 
                  rel="noreferrer" 
                  aria-label="View our Accessibility Statement"
                  className="text-dark link-offset-3"
                >
                  Accessibility Statement
                </a>
              </li>
            </ul>
          </div>
        </div>
        <hr className="mt-4" />
        <div className="mt-4" tabIndex="0" aria-labelledby="Website copyrights section of the footer.">
          <p className="mb-0" tabIndex="0">&copy; {currentYear} K-12 Study by AllyRight. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}
