import { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const newData = [
  { "bin": "193-2193", "height": 386 },
  { "bin": "2193-3193", "height": 223 },
  { "bin": "3193-4193", "height": 174 },
  { "bin": "4193-5193", "height": 50 },
  { "bin": "5193-6193", "height": 5 },
  { "bin": "6193-19193", "height": 1 },
  { "bin": "19193-25193", "height": 2 },
  { "bin": "25193-28193", "height": 1 },
];

export default function DomInteractiveTimePerformance() {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleBarClick = (data) => {
    if (selectedItem === null) {
      setSelectedItem(data);
      return;
    }

    if (selectedItem.payload !== data.payload) {
      setSelectedItem(data);
    } else {
      setSelectedItem(null);
    }
  };

  return (
    <div className="card" style={{ borderRadius: "10px" }}>
      <div className="card-header">
        <p className="h3 modal-title fw-bold">
          DOM Interactive Time Performance Metrics
        </p>
      </div>

      <div className="card-body">
        <div className="container">

          <div className="row">
            <div className="col-12">
              <p tabIndex="0">
                This metric reflects the time it takes for the page to become fully interactive. A prolonged DOM Interactive Time can frustrate users, preventing them from engaging with interactive elements such as forms or navigation menus. Quick interactivity is vital for enhancing user experience and ensuring users can navigate the site efficiently.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
              <div style={{ width: "100%", height: "500px" }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={newData}
                    layout="vertical"
                    margin={{ top: 20, right: 30, bottom: 5, left: 60 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis type="category" dataKey="bin" />
                    <XAxis type="number" />
                    <Bar
                      dataKey="height"
                      fill="#8884d8"
                      onClick={handleBarClick}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>

            <div className="col-12 col-lg-6 d-flex align-items-center">
              <div className="row justify-content-center" style={{ width: "100%" }}>
                <div className="col-md-12 col-lg-10 mb-4">
                  <div className="table-responsive" style={{ margin: "1rem" }}>
                    <table className="table table-bordered table-striped">
                      <caption className="sr-only">
                        Table showing the DOM Interactive Time performance metrics for K12 websites
                      </caption>
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">DOM Interactive Time (in ms)</th>
                          <th scope="col">Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {newData.map((item, index) => (
                          <tr key={index}>
                            <td tabIndex="0">{item.bin}</td>
                            <td tabIndex="0">{item.height}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {selectedItem ? (
        <div
          className="card mt-4"
          style={{
            borderRadius: "10px",
            marginRight: "10px",
            marginLeft: "10px",
            marginBottom: "5px",
          }}
        >
          <div className="card-header">
            <p className="h4 modal-title" tabIndex="0">
              DOM Interactive Time Performance Details
            </p>
          </div>
          <div className="card-body">
            <p tabIndex="0">
              <strong>DOM Interactive Time ({selectedItem.payload.bin}) (in milliseconds): </strong>
              {parseInt(selectedItem.payload.height, 10)} websites
            </p>
          </div>
        </div>
      ) : (
        <p className="text-center" tabIndex="0">
          Click a bar to see detailed interactive time metrics.
        </p>
      )}
    </div>
  );
}
