import GradeOverview from "./GradeCardOverview";
import AccessibilityStatsOverview from "./AccessibilityStatsOverview";
import PerformanceStatsOverview from "./PerformanceStatsOverview";
import GeneralStatsOverview from "./GeneralStatsOverview";
import UsStatesMapAccessibility from "./UsStatesMapAccessibility";
import UsStatesMapPerformance from "./UsStatesMapPerformance";
import TopFiveAccessibilityIssues from "./TopFiveAccessibilityIssues";
import TopFiveStatesPerformance from "./TopFiveStatesPerformance";
import WebsitesWithAccessibilityIssues from "./WebsitesWithAccessibilityIssues";
// import PagesWithAccessibilityIssues from "./PagesWithAccessibilityIssues";
// import ImproperUseOfHeadings from "./ImproperUseOfHeadings";
// import ProportionOfPagesWithAccessibilityIssues from "./ProportionOfPagesWithAccessibilityIssues";
// import AverageNumberOfIssuesInPerformance from "./AverageNumberOfIssuesInPerformance";
import NumberOfCssErrorsPerWebsite from "./NumberOfCssErrorsPerWebsite";
// import NumberOfPagesWithCssErrorsPerWebsite from "./NumberOfPagesWithCssErrorsPerWebsite";
import AveragePageLoadSpeed from "./AveragePageLoadSpeed";
import BackendDurationPerformance from "./BackendDurationPerformance";
import DomContentLoadTimePerformance from "./DomContentLoadTimePerformance";
import DomInteractiveTimePerformance from "./DomInteractiveTimePerformance";
import FirstMeaningfulPaintPerformance from "./FirstMeaningfulPaintPerformance";
import FirstPaintPerformance from "./FirstPaintPerformance";
import FullyLoadedTimePerformance from "./FullyLoadedTimePerformance";
import UnorderedHeadingTagsSummary from "./UnorderedHeadingTagsSummary";
import WebpagesH1Summary from "./WebpagesH1Summary";
// import ContactForm from "./ContactForm";

export default function BodyComponent() {
  return (
    <div className="container mt-5">
      <div className="card" style={{ border: "0px" }}>
        <div className="card-body">
          
          <div className="container">
            <hr />
          </div>
          
          <div className="container">
            <p className="h2 fw-bold text-center modal-title text-uppercase">
              Introduction
            </p>
          </div>

          <div className="container">
            <hr />
          </div>

          <div className="container mt-5">
            <p tabIndex="0">
              In today's digital age, K-12 schools are facing unprecedented challenges as they have been increasingly targeted by ransomware attacks, data breaches, and lawsuits. As experts in website accessibility, security, privacy, and performance, we knew K-12 school websites had challenges, but we didn't anticipate the magnitude of the problems. 
            </p>
            <p tabIndex="0">
              Using our software, AllyRight, we audited 1,200 websites across all 50 states, ensuring a representative sample of schools. The results are alarming: every website has accessibility and performance issues, and many exhibit severe security and privacy vulnerabilities. 
            </p>
            <p tabIndex="0">
              Our goal is to inspire school officials to take action. By improving website accessibility, security, privacy, and performance, schools can create a safer, more inclusive experience for everyone.
            </p>
            <p tabIndex="0">
              <br />Your digital compliance friend,<br /><br />
              Paulo Amaral, MS<br />
              Founder<br />
              AllyRight
            </p>
          </div>

          <div className="container mt-5">
            <hr />
          </div>

          <div className="container">
            <p className="h2 fw-bold text-center modal-title text-uppercase">
              Sampling & Methodology
            </p>
          </div>

          <div className="container">
            <hr />
          </div>

          <div className="container mt-5">
            <p tabIndex="0">
              In order to achieve a nationally representative sample, we created an algorithm that randomly selected schools from all 50 states, covering private and public,  elementary, middle, and high school levels, as well as schools serving multiple grade levels. We ensured that the selected schools were drawn from different parts of each state, avoiding close proximity and including rural, urban, and suburban areas. This approach ensured that the results were representative both at the state and national levels.
            </p>
          </div>

          <div className="container mt-5">
            <GeneralStatsOverview />
          </div>

          <div className="container mt-5">
            <hr />
          </div>

          <div className="container">
            <p className="h2 fw-bold text-center modal-title text-uppercase">
              Security & Privacy
            </p>
          </div>

          <div className="container">
            <hr />
          </div>

          <div className="container mt-5">
            <p tabIndex="0">
              It is important to note that we only performed non-invasive security audits, which are not the most sophisticated audits. Still, we found critical vulnerabilities across several school websites. These audits also uncovered significant data privacy issues, including the exposure of very personal student information, which is extremely concerning.
            </p>
            <p tabIndex="0">
              The gravity of this situation cannot be overstated. In the hands of malicious actors, these findings could pose severe risk to schools, their staff, and, most disturbingly, the students. Therefore, we will not publish these findings. Rather, we will only share these results with appropriate school personnel.
            </p>
          </div>

          <div className="container mt-5">
            <hr />
          </div>

          <div className="container">
            <p className="h2 fw-bold text-center modal-title text-uppercase">
              Accessibility
            </p>
          </div>

          <div className="container">
            <hr />
          </div>

          <div className="container mt-5">
            <AccessibilityStatsOverview />
          </div>

          <div className="container mt-5">
            <UsStatesMapAccessibility />
          </div>

          <div className="container mt-5">
            <WebsitesWithAccessibilityIssues />
          </div>

          {/* <div className="container mt-5">
            <ProportionOfPagesWithAccessibilityIssues />
          </div> */}

          <div className="container mt-5">
            <TopFiveAccessibilityIssues />
          </div>

          <div className="container mt-5">
            <hr />
          </div>

          <div className="container">
            <p className="h2 fw-bold text-center modal-title text-uppercase">
              Performance
            </p>
          </div>

          <div className="container">
            <hr />
          </div>

          <div className="container mt-5">
            <PerformanceStatsOverview />
          </div>

          <div className="container mt-5">
            <p tabIndex="0">
              This section examines the performance issues prevalent in K-12 school websites. Our study reveals that nearly all of the audited sites suffer from significant performance problems, including slow loading times, poor responsiveness, and inefficient resource utilization. These issues negatively affect user experience and also hinder the accessibility and usability of the websites.
            </p>

            <p tabIndex="0">
              It's crucial to recognize the significant overlap between a website's performance and its security. As mentioned in the “Security & Privacy” section, to protect the safety and privacy of students, school personnel, and schools, we will not disclose any information that could reveal security vulnerabilities. Confidential information will be shared exclusively with district and school administrators upon their request. 
            </p>

            <p tabIndex="0">
              All information in this section focuses on performance metrics and optimization strategies, ensuring that no sensitive security details are disclosed while providing valuable insights for enhancing website efficiency.
            </p>

            <p tabIndex="0">
              With this in mind, we can share that several, if not most of the performance issues we observed were caused by the overuse of JavaScript, which was often unnecessary, large and unoptimized files, including media files, text, spreadsheet, and pdf files.
            </p>
            <p tabIndex="0">
              Schools must prioritize website optimization to improve load times, mobile responsiveness, and user experience.
            </p>
          </div>

          <div className="container mt-5">
            <AveragePageLoadSpeed />
          </div>
          
          <div className="container mt-5">
            <TopFiveStatesPerformance />
          </div>

          <div className="container mt-5">
            <UsStatesMapPerformance />
          </div>

          {/* <div className="container mt-5">
            <AverageNumberOfIssuesInPerformance />
          </div> */}

          <div className="container mt-5">
            <WebpagesH1Summary />
          </div>

          <div className="container mt-5">
            <UnorderedHeadingTagsSummary />
          </div>

          <div className="container mt-5">
            <NumberOfCssErrorsPerWebsite />
          </div>

          <div className="container mt-5">
            <BackendDurationPerformance />
          </div>

          <div className="container mt-5">
            <DomContentLoadTimePerformance />
          </div>

          <div className="container mt-5">
            <DomInteractiveTimePerformance />
          </div>

          <div className="container mt-5">
            <FirstMeaningfulPaintPerformance />
          </div>

          <div className="container mt-5">
            <FirstPaintPerformance />
          </div>

          <div className="container mt-5">
            <FullyLoadedTimePerformance />
          </div>

          {/* <div className="container mt-5">
            <NumberOfPagesWithCssErrorsPerWebsite />
          </div> */}

          {/* <div className="container mt-5">
            <ImproperUseOfHeadings />
          </div> */}

          <div className="container mt-5">
            <p
              tabIndex="0"
              style={{
                paddingTop: "1rem",
                paddingRight: "1rem",
                paddingLeft: "1rem",
              }}
            >
              In conclusion, our study reveals critical performance issues across K-12 school websites, characterized by slow loading times and inefficient resource use, which hinder user experience and accessibility. To enhance online engagement, schools must prioritize website optimization, addressing excessive JavaScript and unoptimized files. By doing so, they can create a more efficient, user-friendly environment that better serves students, parents, and educators, ultimately strengthening the entire educational community.
            </p>
          </div>

          <div className="container mt-5">
            <hr />
          </div>

          <div className="container">
            <p className="h2 fw-bold text-center modal-title text-uppercase">
              Conclusion
            </p>
          </div>

          <div className="container">
            <hr />
          </div>

          <div className="container mt-5">
            <GradeOverview />
          </div>

          <div className="container mt-5">
            <p tabIndex="0">
              In summary, our study emphasizes the vital connection between accessibility, performance, and security as essential elements of effective web design. Conducting a thorough audit across numerous pages necessitates a programmatic approach, and while establishing a grading system is complex, we utilized AllyRight's scoring system to derive valuable insights. The results indicate a compelling need for immediate improvements, encouraging schools to elevate their online platforms. By focusing on these enhancements, educational institutions can foster a more inclusive, responsive, and secure digital environment for all users.
            </p>
          </div>

          {/* <div className="container mt-5">
            <div className="card" style={{ borderRadius: "10px", backgroundColor: "#EFF4FF" }}>
              <div className="card-header">
                <p className="h3 modal-title fw-bold text-center" tabIndex="0">Get more insights from our study in our upcoming webinars</p>
              </div>
              <div className="card-body">
                <ContactForm />
              </div>
            </div>
          </div> */}

        </div>
      </div>
    </div>
  );
}
