
export default function NavBar({ isDownloadEnabled = false }) {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-black text-white">
      <div className="container">
        <a href="/" className="navbar-brand" aria-label="Goto document root. that is main page." tabIndex="0">
          <img src="/logo.svg" alt="Logo" width="100" height="25" className="d-inline-block" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                href="https://allyright.com/"
                rel="noreferrer"
                target="_blank"
                className="nav-link text-white"
                aria-label="Visit AllyRight Home"
              >
                AllyRight Home
              </a>
            </li>
            <li className="nav-item">
              <a
                href="https://allyright.com/about-us"
                rel="noreferrer"
                target="_blank"
                className="nav-link text-white"
                aria-label="Learn more about AllyRight"
              >
                About AllyRight
              </a>
            </li>
            {isDownloadEnabled && (
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/k-12-study.pdf"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Download the study from AllyRight"
                >
                  Download Study
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}
