
export default function AccessibilityStatement() {
  const dateUpdated = '10/21/2024';

  return (
    <>
      <h1 className="mb-4" tabIndex="0">Accessibility Statement</h1>
      <section aria-labelledby="compliance-status" tabIndex="0">
        <p tabIndex="0">
          We are committed to ensuring that our website is accessible to everyone, including individuals with disabilities. We strive to adhere to the Web Content Accessibility Guidelines (WCAG) 2.2 at levels A, AA, and AAA, as well as Section 508 standards.
        </p>
        <h2 id="compliance-status" className="mt-4" tabIndex="0">Compliance Status</h2>
        <p tabIndex="0">
          Our website aims to comply with WCAG 2.1 standards and Section 508. We continuously review and improve our website to ensure that it meets these accessibility standards. While we strive for full compliance, there may be instances where certain elements of our website may not fully meet accessibility standards.
        </p>
        <h2 className="mt-4" tabIndex="0">Feedback and Assistance</h2>
        <p tabIndex="0">
          If you experience any difficulties accessing content on our website or have any questions regarding our accessibility practices, please contact us. We value your feedback and are committed to addressing any accessibility issues.
        </p>
        <p tabIndex="0">
          <strong>Contact Us:</strong> <a href="https://allyright.com/contact-us" target="_blank" rel="noreferrer" tabIndex="0">https://allyright.com/contact-us</a>
        </p>
        <h2 className="mt-4" tabIndex="0">Continuous Improvement</h2>
        <p tabIndex="0">
          We are continuously working to enhance the accessibility of our website. As technology and accessibility guidelines evolve, we will update our practices and make necessary adjustments to improve accessibility.
        </p>
        <h2 className="mt-4" tabIndex="0">Third-Party Content</h2>
        <p tabIndex="0">
          Our website may contain links to third-party websites and services. While we strive to ensure that these third parties also adhere to accessibility standards, we cannot guarantee their compliance.
        </p>
        <h2 className="mt-4" tabIndex="0">Date of Last Update</h2>
        <p tabIndex="0">
          This accessibility statement was last updated on {dateUpdated}.
        </p>
      </section>
    </>
  );
}
