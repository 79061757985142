import { useState, useEffect } from 'react';
import ReactSpeedometer from 'react-d3-speedometer';

export default function PageLoadSpeedometer() {
    const [speed, setSpeed] = useState(0);

    useEffect(() => {
        setSpeed(12.8);
    }, []);

    return (
        <div 
          className="text-center"
          style={{ width: '100%', borderRadius: '10px' }}
          role="region" 
          aria-label="Page Load Speed"
        >
            <div className="card-body">
                <div className="d-flex align-items-center justify-content-center" style={{ height: '125px' }}>
                    <ReactSpeedometer
                        width={240}
                        height={125}
                        needleHeightRatio={0.7}
                        paddingHorizontal={5}
                        value={speed}
                        minValue={0}
                        maxValue={22}
                        customSegmentStops={[
                            0, 2, 4, 6, 8, 12, 14, 16, 18, 20, 22
                        ]}
                        segment={1000}
                        segmentColors={[
                            "#0C9F5F",
                            "#C5E02D",
                            "#FFC533",
                            "#FF7A33",
                            "#FF7A33",
                            "#E32D2D",
                            "#E32D2D",
                            "#E32D2D",
                            "#E32D2D",
                            "#E32D2D",
                            "#E32D2D",
                        ]}
                        currentValueText={speed.toString()}
                        needleColor={"black"}
                        customSegmentLabels={[
                            {
                                text: "Good",
                                position: "OUTSIDE",
                                color: "#0C9F5F",
                            },
                            {
                                text: "2",
                                position: "OUTSIDE",
                            },
                            {
                                position: "OUTSIDE",
                            },
                            {
                                text: "6",
                                position: "OUTSIDE",
                            },
                            {
                                position: "OUTSIDE",
                            },
                            {
                                text: "12",
                                position: "OUTSIDE",
                            },
                            {
                                position: "OUTSIDE",
                            },
                            {
                                position: "OUTSIDE",
                            },
                            {
                                text: "16",
                                position: "OUTSIDE",
                            },
                            {
                                text: "Bad",
                                position: "OUTSIDE",
                                color: "#E32D2D",
                            },
                        ]}
                        ringWidth={45}
                        needleTransitionDuration={3333}
                        needleTransition="easeElastic"
                        textColor={"black"}
                    />
                </div>
                <p className="card-text mb-0" style={{marginTop: '0.78rem', zoom: 1.25}}>
                    <strong>Average Speed {Math.floor(speed)}s</strong>
                </p>
            </div>
        </div>
    );
}
