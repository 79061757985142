import StatCard from './StatCard';

export default function AccessibilityStatsOverview() {
  return (
    <div className="row justify-content-center">
      <div className="col-md-6 col-lg-4 mb-4">
        <StatCard icon="bi-exclamation-octagon" number="4,257,374" label="Number of issues" cardStyle={{ backgroundColor: "#ffdede" }} />
      </div>
      <div className="col-md-6 col-lg-4 mb-4">
        <StatCard icon="bi-window-fullscreen" number="1,200" label="Websites with issues" cardStyle={{ backgroundColor: "#ffdede" }} />
      </div>
      <div className="col-md-6 col-lg-4 mb-4">
        <StatCard icon="bi-window-fullscreen" number="100%" label="% of websites with issues" cardStyle={{ backgroundColor: "#ffdede" }} />
      </div>
      <div className="clearfix"></div>
      <div className="col-md-6 col-lg-4 mb-4">
        <StatCard icon="bi-window-fullscreen" number="3,512.68" label="Number of avg. issues per website" cardStyle={{ backgroundColor: "#ffdede" }} />
      </div>
      <div className="col-md-6 col-lg-4 mb-4">
        <StatCard icon="bi-file-text" number="70.22" label="Number of avg. issues per page" cardStyle={{ backgroundColor: "#ffdede" }} />
      </div>
    </div>
  );
}
