import GradeCard from './GradeCard';

export default function GradeOverview() {
  return (
    <div className="row" role="region" aria-label="Grade Overview" tabIndex="0">
      <div className="col-md-4 mb-4">
        <GradeCard grade="F" label="AllyRight™ Score" tabIndex="0" />
      </div>
      <div className="col-md-4 mb-4">
        <GradeCard grade="F" label="Accessibility Score" tabIndex="0" />
      </div>
      <div className="col-md-4 mb-4">
        <GradeCard grade="F" label="Performance Score" tabIndex="0" />
      </div>
    </div>
  );
}
