import { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const newData = [
  { "bin": "34-1534", "height": 287 },
  { "bin": "1534-3034", "height": 356 },
  { "bin": "3034-4534", "height": 103 },
  { "bin": "4534-6034", "height": 41 },
  { "bin": "6034-7534", "height": 46 },
  { "bin": "7534-15034", "height": 7 },
  { "bin": "15034-22534", "height": 0 },
  { "bin": "22534-45034", "height": 1 },
];

export default function FullyLoadedTimePerformance() {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleBarClick = (data) => {
    if (selectedItem === null) {
      setSelectedItem(data);
      return;
    }

    if (selectedItem.payload !== data.payload) {
      setSelectedItem(data);
    } else {
      setSelectedItem(null);
    }
  };

  return (
    <div className="card" style={{ borderRadius: "10px" }}>
      <div className="card-header">
        <p className="h3 modal-title fw-bold">
          Fully Loaded Time Performance Metrics
        </p>
      </div>

      <div className="card-body">
        <div className="container">
          
          <div className="row">
            <div className="col-12">
              <p tabIndex="0">
                Fully Loaded Time measures the total time taken for a web page to completely load all of its resources. A long fully loaded time can detract from user experience, especially for users on slower connections or devices. Optimizing this metric is essential for ensuring that users can access all functionalities without unnecessary delays.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
              <div style={{ width: "100%", height: "500px" }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={newData}
                    layout="vertical"
                    margin={{ top: 20, right: 30, bottom: 5, left: 60 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis type="category" dataKey="bin" />
                    <XAxis type="number" />
                    <Bar
                      dataKey="height"
                      fill="#8884d8"
                      onClick={handleBarClick}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>

            <div className="col-12 col-lg-6 d-flex align-items-center">
              <div className="row justify-content-center" style={{ width: "100%" }}>
                <div className="col-md-12 col-lg-10 mb-4">
                  <div className="table-responsive" style={{ margin: "1rem" }}>
                    <table className="table table-bordered table-striped">
                      <caption className="sr-only">
                        Table showing the Fully Loaded Time performance metrics for K12 websites
                      </caption>
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">Fully Loaded Time (in ms)</th>
                          <th scope="col">Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {newData.map((item, index) => (
                          <tr key={index}>
                            <td tabIndex="0">{item.bin}</td>
                            <td tabIndex="0">{item.height}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {selectedItem ? (
        <div
          className="card mt-4"
          style={{
            borderRadius: "10px",
            marginRight: "10px",
            marginLeft: "10px",
            marginBottom: "5px",
          }}
        >
          <div className="card-header">
            <p className="h4 modal-title" tabIndex="0">
              Fully Loaded Time Performance Details
            </p>
          </div>
          <div className="card-body">
            <p tabIndex="0">
              <strong>Fully Loaded Time ({selectedItem.payload.bin}) (in milliseconds): </strong>
              {parseInt(selectedItem.payload.height, 10)} websites
            </p>
          </div>
        </div>
      ) : (
        <p className="text-center" tabIndex="0">
          Click a bar to see detailed Fully Loaded Time metrics.
        </p>
      )}
    </div>
  );
}
