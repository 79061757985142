import "@fontsource/poppins";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainPage from './pages/MainPage';
import AccessibilityStatement from './pages/AccessibilityStatementPage';

export default function App() {
  return (
    <Router>
      <div className="antialiased" style={{ fontFamily: "Poppins" }}>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/accessibility-statement" element={<AccessibilityStatement />} />
        </Routes>
      </div>
    </Router>
  );
}
