import StatCard from './StatCard';

export default function PerformanceStatsOverview() {
  return (
    <div className="row justify-content-center">
      <div className="col-md-6 col-lg-4 mb-4">
        <StatCard icon="bi-speedometer2" number="14,369,184" label="Number of Performance Issues" cardStyle={{ backgroundColor: "#ffdede" }} />
      </div>
      
      <div className="col-md-6 col-lg-4 mb-4">
        <StatCard icon="bi-exclamation-octagon" number="249" label="Average Performance Issues per Website" cardStyle={{ backgroundColor: "#ffdede" }} />
      </div>
    </div>
  );
}
