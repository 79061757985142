import React from 'react';
import StackedProgressBar from './StackedProgressBar';

const data = [
  { label: 'Single H1', value: 33374, percentage: '7.91', color: '#4CAF50' }, // Green
  { label: 'Multiple H1', value: 32201, percentage: '7.41', color: '#2196F3' }, // Blue
  { label: 'Without H1', value: 389078, percentage: '84.68', color: '#F44336' } // Red
];

export default function WebpagesH1Summary() {
  return (
    <div className="card" style={{ borderRadius: "10px" }}>
      <div className="card-header">
        <p className="h3 modal-title fw-bold">
          Summary of Webpages by H1 Usage
        </p>
      </div>

      <div className="card-body">
        <p>
          This chart displays the amounts and percentages of webpages categorized by their H1 usage:
          those with a single H1, multiple H1s, and none at all.
        </p>
        <StackedProgressBar
          data={data.map(item => ({
            value: (parseFloat(item.percentage)),
            color: item.color,
            label: item.label,
          }))}
          progressBarStyle={{ height: '30px', borderRadius: '10px', fontSize: '0.85rem' }}
        />
      </div>
    </div>
  );
}
