import { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const newData = [
  { bin: "0-10", height: 215 },
  { bin: "10-20", height: 104 },
  { bin: "20-30", height: 91 },
  { bin: "30-40", height: 104 },
  { bin: "40-50", height: 82 },
  { bin: "50-60", height: 93 },
  { bin: "60-70", height: 60 },
  { bin: "70-80", height: 75 },
  { bin: "80-90", height: 71 },
  { bin: "90-100", height: 317 },
];

export default function UnorderedHeadingTagsSummary() {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleBarClick = (data) => {
    if (selectedItem === null) {
      setSelectedItem(data);
      return;
    }

    if (selectedItem.payload !== data.payload) {
      setSelectedItem(data);
    } else {
      setSelectedItem(null);
    }
  };

  return (
    <div className="card" style={{ borderRadius: "10px" }}>
      <div className="card-header">
        <p className="h3 modal-title fw-bold">
          Summary of Unordered Heading Tags
        </p>
      </div>

      <div className="card-body">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p tabIndex="0">
                This chart illustrates the distribution of unordered heading tags across webpages, highlighting the counts of H2 and H3 tags. It categorizes webpages based on the number of unordered headings present, providing insights into their structure and accessibility.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
              <div style={{ width: "100%", height: "500px" }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={newData}
                    layout="vertical"
                    margin={{ top: 20, right: 30, bottom: 5, left: 60 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis type="category" dataKey="bin" />
                    <XAxis type="number" />
                    <Bar
                      dataKey="height"
                      fill="#8884d8"
                      onClick={handleBarClick}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>

            <div className="col-12 col-lg-6 d-flex align-items-center">
              <div className="row justify-content-center" style={{ width: "100%" }}>
                <div className="col-md-12 col-lg-10 mb-4">
                  <div className="table-responsive" style={{ margin: "1rem" }}>
                    <table className="table table-bordered table-striped">
                      <caption className="sr-only">
                        Table showing the count of unordered heading tags
                      </caption>
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">Heading Count Range</th>
                          <th scope="col">Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {newData.map((item, index) => (
                          <tr key={index}>
                            <td tabIndex="0">{item.bin}</td>
                            <td tabIndex="0">{item.height}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {selectedItem ? (
        <div
          className="card mt-4"
          style={{
            borderRadius: "10px",
            marginRight: "10px",
            marginLeft: "10px",
            marginBottom: "5px",
          }}
        >
          <div className="card-header">
            <p className="h4 modal-title" tabIndex="0">
              Count of Unordered Heading Tags
            </p>
          </div>
          <div className="card-body">
            <p tabIndex="0">
              <strong>Heading Count ({selectedItem.payload.bin}): </strong>
              {parseInt(selectedItem.payload.height, 10)}
            </p>
          </div>
        </div>
      ) : (
        <p className="text-center" tabIndex="0">
          Click a bar to view the details.
        </p>
      )}
    </div>
  );
}
