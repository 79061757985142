import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * StackedProgressBar Component
 *
 * A customizable stacked progress bar that visually represents progress
 * using multiple segments. Each segment can be clicked to display additional
 * details. The component is designed with accessibility in mind, adhering
 * to WCAG 2.1 AAA and 2.2 AAA standards.
 *
 * Props:
 * - data (Array): An array of objects representing the segments of the progress bar.
 *   Each object must contain:
 *   - value (Number): The percentage value of the segment. Must sum to 100 across all segments.
 *   - color (String): A valid CSS color value (hex, RGB, etc.) for the segment's background.
 *   - label (String): The label to display inside the segment.
 * - progressBarStyle (Object): Optional custom styles for the progress bar.
 *   This object can include any valid CSS properties.
 *
 * Accessibility:
 * - The progress bar uses ARIA roles and properties to enhance screen reader support.
 * - Each segment has role="button" for keyboard accessibility.
 * - Clicking a segment toggles the display of its details, presented in a separate section.
 * - An error message is shown if the total of the values does not equal 100%, using role="alert".
 */
const StackedProgressBar = ({ data, progressBarStyle }) => {
  const [selectedValue, setSelectedValue] = useState(null);

  // Calculate total percentage
  const totalValue = data.reduce((acc, item) => acc + item.value, 0);
  const isError = totalValue !== 100;

  const handleSegmentClick = (item) => {
    setSelectedValue(selectedValue === item ? null : item);
  };

  // Effect to select the highest percentage segment
  useEffect(() => {
    if (data.length > 0) {
      const highestSegment = data.reduce((prev, current) => 
        (current.value > prev.value ? current : prev), data[0]);
      setSelectedValue(highestSegment);
    }
  }, [data]);

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-12">
          {isError && (
            <div className="alert alert-danger" role="alert" aria-live="assertive">
              Progress bar values not correct. Total must equal 100% (current total: {totalValue}%)
            </div>
          )}
          <div 
            className="progress" 
            style={{ ...progressBarStyle, height: '30px' }} 
            role="progressbar" 
            aria-valuenow={totalValue} 
            aria-valuemin="0" 
            aria-valuemax="100"
          >
            {data.map((item, index) => (
              <div
                key={index}
                className="progress-bar"
                style={{
                  width: `${item.value}%`,
                  backgroundColor: item.color,
                  cursor: 'pointer',
                  height: '100%', 
                  position: 'relative',
                }}
                role="button"
                tabIndex={0}
                onClick={() => handleSegmentClick(item)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handleSegmentClick(item);
                }}
                aria-label={`Segment: ${item.value}%, Color: ${item.color}`}
                aria-expanded={selectedValue === item}
              >
                <span style={{
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)',
                  color: '#fff',
                  fontWeight: 'bold'
                }}>
                  {`${item.label} (${item.value}%)`.length >= 10 ? `${item.value}%` : `${item.label} (${item.value}%)`}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <div className="border p-3" role="region" aria-labelledby="segment-details" aria-hidden={!selectedValue}>
            <h5 id="segment-details">Segment Details</h5>
            {selectedValue ? (
              <div>
                <p>
                  <span
                    style={{
                      width: "10px",
                      height: "10px",
                      backgroundColor: selectedValue.color,
                      display: "inline-block",
                      marginRight: "5px"
                    }}
                  ></span>
                  {selectedValue.label}: {selectedValue.value}%
                </p>
              </div>
            ) : (
              <p>Click a segment to see details here.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

StackedProgressBar.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  progressBarStyle: PropTypes.object,
};

export default StackedProgressBar;
