import { onCLS, onINP, onFCP, onLCP, onTTFB } from 'web-vitals';

function reportWebVitals(onPerfEntry) {
  if (onPerfEntry && typeof onPerfEntry === 'function') {
    const logMetric = (metric) => {
      onPerfEntry(metric);
    };

    onCLS(logMetric);
    onINP(logMetric);
    onFCP(logMetric);
    onLCP(logMetric);
    onTTFB(logMetric);
  }
}

export default reportWebVitals;
