import { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const newData = [
  { name: "Arkansas", value: 5851 },
  { name: "Illinois", value: 5400 },
  { name: "Maine", value: 5386 },
  { name: "South Dakota", value: 5152 },
  { name: "Idaho", value: 4891 },
];

export default function TopFiveStates() {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleBarClick = (data) => {
    if (selectedItem === null) {
      setSelectedItem(data);
      return;
    }

    if (selectedItem.payload !== data.payload) {
      setSelectedItem(data);
    } else {
      setSelectedItem(null);
    }
  };

  useEffect(() => {
    setSelectedItem(newData[0]);
  }, []);

  return (
    <div className="card" style={{ borderRadius: "10px" }}>
      <div className="card-header">
        <p className="h3 modal-title fw-bold">
          States with the Most Performance Issues
        </p>
      </div>
      <div className="card-body">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p tabIndex="0">
                Performance issues vary widely across states. The chart and
                table below identify the five states with the highest number of
                performance issues (Arkansas, Illinois, Maine, South Dakota, and
                Idaho) and the number of performance issues we found in the
                websites of each state.
              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div
              className="col-12 col-lg-6 d-flex align-items-center"
              style={{ height: "400px" }}
            >
              <ResponsiveContainer>
                <BarChart
                  data={newData}
                  layout="vertical"
                  margin={{ top: 20, right: 30, bottom: 5, left: 30 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <YAxis type="category" dataKey="name" />
                  <XAxis type="number" />
                  <Bar
                    dataKey="value"
                    fill="#8884d8"
                    onClick={handleBarClick}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>

            <div className="col-12 col-lg-6 d-flex align-items-center">
              <div className="d-block" style={{ width: "100%" }}>
                {selectedItem ? (
                  <div
                    className="card mt-4"
                    style={{
                      borderRadius: "10px",
                      marginRight: "10px",
                      marginLeft: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    <div className="card-header">
                      <p className="h4 modal-title" tabIndex="0">
                        {selectedItem.name}
                      </p>
                    </div>
                    <div className="card-body">
                      <p tabIndex="0">
                        <strong>Total Performance Issues:</strong>
                        {selectedItem.value.toLocaleString()}
                      </p>
                    </div>
                  </div>
                ) : (
                  <p className="text-center" tabIndex="0">
                    Click a bar to see the details.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
