import { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const newData = [
  { "bin": "193-993", "height": 291 },
  { "bin": "993-1393", "height": 348 },
  { "bin": "1393-1793", "height": 162 },
  { "bin": "1793-2193", "height": 37 },
  { "bin": "2193-2593", "height": 1 },
  { "bin": "2593-6593", "height": 2 },
  { "bin": "6593-6993", "height": 2 },
  { "bin": "6993-7393", "height": 0 },
];

export default function FirstPaintPerformance() {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleBarClick = (data) => {
    if (selectedItem === null) {
      setSelectedItem(data);
      return;
    }

    if (selectedItem.payload !== data.payload) {
      setSelectedItem(data);
    } else {
      setSelectedItem(null);
    }
  };

  return (
    <div className="card" style={{ borderRadius: "10px" }}>
      <div className="card-header">
        <p className="h3 modal-title fw-bold">
          First Paint Performance Metrics
        </p>
      </div>

      <div className="card-body">
        <div className="container">
          
          <div className="row">
            <div className="col-12">
              <p tabIndex="0">
                First Paint marks the point at which any part of the page is rendered on the screen. Although it doesn’t indicate content readiness, it’s an important metric for understanding initial loading performance. A quick First Paint can create a sense of speed, encouraging users to stay engaged with the site.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
              <div style={{ width: "100%", height: "500px" }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={newData}
                    layout="vertical"
                    margin={{ top: 20, right: 30, bottom: 5, left: 60 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis type="category" dataKey="bin" />
                    <XAxis type="number" />
                    <Bar
                      dataKey="height"
                      fill="#8884d8"
                      onClick={handleBarClick}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>

            <div className="col-12 col-lg-6 d-flex align-items-center">
              <div className="row justify-content-center" style={{ width: "100%" }}>
                <div className="col-md-12 col-lg-10 mb-4">
                  <div className="table-responsive" style={{ margin: "1rem" }}>
                    <table className="table table-bordered table-striped">
                      <caption className="sr-only">
                        Table showing the First Paint performance metrics for K12 websites
                      </caption>
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">First Paint Time (in ms)</th>
                          <th scope="col">Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {newData.map((item, index) => (
                          <tr key={index}>
                            <td tabIndex="0">{item.bin}</td>
                            <td tabIndex="0">{item.height}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {selectedItem ? (
        <div
          className="card mt-4"
          style={{
            borderRadius: "10px",
            marginRight: "10px",
            marginLeft: "10px",
            marginBottom: "5px",
          }}
        >
          <div className="card-header">
            <p className="h4 modal-title" tabIndex="0">
              First Paint Performance Details
            </p>
          </div>
          <div className="card-body">
            <p tabIndex="0">
              <strong>First Paint Time ({selectedItem.payload.bin}) (in milliseconds): </strong>
              {parseInt(selectedItem.payload.height, 10)} websites
            </p>
          </div>
        </div>
      ) : (
        <p className="text-center" tabIndex="0">
          Click a bar to see detailed First Paint metrics.
        </p>
      )}
    </div>
  );
}
