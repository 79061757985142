import { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const newData = [
  {
    x_pos: 126.5,
    label: "0-253",
    count: 859,
  },
  {
    x_pos: 380,
    label: "253-507",
    count: 37,
  },
  {
    x_pos: 633.5,
    label: "507-760",
    count: 32,
  },
  {
    x_pos: 887,
    label: "760-1014",
    count: 1,
  },
  {
    x_pos: 1140.5,
    label: "1014-1267",
    count: 3,
  },
];

export default function NumberOfCssErrorsPerWebsite() {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleBarClick = (data) => {
    if (selectedItem === null) {
      setSelectedItem(data);
      return;
    }

    if (selectedItem.payload !== data.payload) {
      setSelectedItem(data);
    } else {
      setSelectedItem(null);
    }
  };

  useEffect(() => {
    setSelectedItem(newData[0]);
  }, []);

  return (
    <div className="card" style={{ borderRadius: "10px" }}>
      <div className="card-header">
        <p className="h3 modal-title fw-bold">
          Number of CSS Errors per Website
        </p>
      </div>
      <div className="card-body">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p tabIndex="0">
                In this section, we analyze the frequency of CSS errors found on
                school websites.
              </p>
              <p tabIndex="0">
                CSS, or Cascading Style Sheets, is essential for defining the
                visual appearance of web pages, including fonts, colors, and
                layouts. Errors in CSS can lead to visual discrepancies, such as
                incorrect formatting and misaligned content. These issues can
                disrupt the user experience, making the site less accessible and
                harder to use, especially for individuals relying on assistive
                technologies.
              </p>
            </div>
          </div>

          <div className="row">
            <div
              className="col-12 col-lg-6 d-flex align-items-center"
              style={{ height: "400px" }}
            >
              <ResponsiveContainer>
                <BarChart
                  data={newData}
                  layout="vertical"
                  margin={{ top: 20, right: 30, bottom: 5, left: 30 }}  
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <YAxis type="category" dataKey="label" />
                  <XAxis type="number" />
                  <Bar
                    dataKey="count"
                    fill="#8884d8"
                    onClick={handleBarClick}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>

            <div className="col-12 col-lg-6 d-flex align-items-center">
              <div className="d-block" style={{ width: "100%" }}>
                {selectedItem ? (
                  <div
                    className="card mt-4"
                    style={{
                      borderRadius: "10px",
                      marginRight: "10px",
                      marginLeft: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    <div className="card-header">
                      <p className="h4 modal-title" tabIndex="0">
                        Count of CSS Errors
                      </p>
                    </div>
                    <div className="card-body">
                      <p tabIndex="0">
                        <strong>Count ({selectedItem.label}):</strong>{" "}
                        {parseInt(selectedItem.count, 10)}
                      </p>
                    </div>
                  </div>
                ) : (
                  <p className="text-center" tabIndex="0">
                    Click a bar to see the details.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
